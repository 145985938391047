@import '@angular/cdk/overlay-prebuilt.css';
@import './variables.scss';
@import './mixins.scss';
@import './fonts.scss';
@import './picker.scss';
@import '~@ng-select/ng-select/themes/default.theme.css';

/* You can add global styles to this file, and also import other style files */
span,
img,
input,
button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
}

h2 {
  font-weight: $h2;
}

h6 {
  font-weight: 900;
}

h6 {
  letter-spacing: -0.26px;
  color: $charcoal;
}

/* App styles */
.full-w {
  width: 100%;
}

app-home {
  height: 100vh;
  display: grid;
}

.link-button {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.popover-panel {
  border-radius: 16px;
  overflow-y: scroll;
  height: 90%;
}

.popover-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  height: auto;
}

.popover-panel-details {
  border-radius: 16px;
  overflow-y: auto;
  height: 80%;
}

.popover-details-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  height: auto;
}

.quala-dropdown {
  position: relative;
  width: 100%;
}

.quala-dropdown select {
  font-family: $font-family;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.quala-dropdown:after {
  pointer-events: none;
  content: '\f078';
  font-family: FontAwesome;
  color: $jungle-green !important;
  font-size: 16px;
  position: absolute;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.26px;
  text-align: right;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.toolbar-container {
  .role-picker-container {
    .ng-star-inserted {
      #role-picker {
        .auth-role-form {
          #user-roles {
            .dropdown-container {
              .ng-dropdown-panel .ng-dropdown-panel-items {
                .ng-option {
                  font-size: $base-text-size;
                  font-weight: $regular;
                }
              }
              .custom-select .ng-select-container {
                font-weight: $regular !important;

                .ng-value-container {
                  .ng-value,
                  .ng-input > input,
                  .ng-placeholder {
                    font-size: $base-text-size;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.app-row-component {
  &.new-item {
    .row-container {
      .row-component {
        font-weight: 700;
      }
    }
  }
}

.mat-drawer-inner-container {
  overflow: overlay !important;
}

mat-sidenav-container {
  .mat-drawer-content {
    width: 100%;
    display: grid;
    margin-left: 0px !important;
    grid-template-rows: auto 1fr;
    padding-bottom: 32px;
  }

  .mat-drawer-side {
    border-right-color: transparent;
  }

  mat-sidenav {
    &.mat-drawer {
      position: relative;
    }
    .mat-drawer-inner-container {
      height: 100vh;
      overflow: hidden !important;
    }
    &.minimalist-sidenav .mat-drawer-inner-container {
      margin-right: 20px;
    }
  }
}

.mat-mdc-dialog-surface {
  padding: 1.5rem;
  border-radius: $large-gap-size;
}

ng-select.has-input-icon {
  .ng-dropdown-panel-items {
    padding: 8px 20px 8px 36px;
  }

  &.ng-select-single .ng-select-container {
    .ng-value-container {
      gap: 8px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;

      .ng-value {
        width: 100%;
        padding-left: 36px;
        padding-right: 40px;
        font-size: $select-size;
      }
      .ng-input {
        top: 0;
        padding-left: 46px;
        padding-right: 40px;
        display: flex !important;
      }
      .ng-placeholder {
        padding-left: 36px;
        padding-right: 40px;
      }
    }
  }
}

.ng-select {
  .ng-select-container {
    border: none;
    border-radius: 0.25rem;
    font-size: $select-size;
    background-color: transparent;
    border: 2px solid $primary-border;

    .ng-input {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      input {
        height: 32px;
      }
    }

    .ng-value {
      width: 100%;

      .ng-star-inserted {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .ng-placeholder {
      overflow: hidden;
      padding-right: 40px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.ng-select-single .ng-select-container {
    background-color: transparent;
    .ng-value-container {
      .ng-input {
        top: 0;
        bottom: 0;
        padding-right: 40px;
        display: flex !important;

        > input {
          padding: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: $select-size;
        }
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened) {
    .ng-select-container {
      border-color: $secondary-color !important;
    }
  }

  > .ng-dropdown-panel {
    margin-top: 0px;
    border: 2px solid $primary-border;
    border-top: 0px;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    @supports not (selector(::-webkit-scrollbar)) {
      scrollbar-color: $primary-color transparent;
    }

    @supports selector(::-webkit-scrollbar) {
      .scroll-host:hover {
        --safari-fix: ; /* This custom property invalidates styles on hover, thereby enforcing a style recomputation. This is needed to work around a bug in Safari. */
      }
      .scroll-host::-webkit-scrollbar-thumb {
        height: 50px;
        border-radius: 8px;
        background: $primary-color;
      }
      .scroll-host::-webkit-scrollbar-track {
        background: transparent;
      }
      .scroll-host::-webkit-scrollbar {
        max-width: 6px;
        max-height: var(--scrollbar-width-legacy);
      }
    }

    .ng-dropdown-panel-items {
      .ng-option.ng-option-marked {
        color: $secondary-text;
        background-color: $primary-color;
      }
    }
  }

  &.ng-invalid .ng-select-container {
    border-color: $red;
  }

  .ng-select-container {
    height: 36px !important;
  }

  .ng-arrow-wrapper {
    display: none;
  }

  .ng-clear-wrapper {
    display: none;
  }
}

.custom-dialog {
  mat-dialog-container {
    .mat-mdc-dialog-surface {
      padding: 1rem;
    }
  }
}

.no-scroll-dialog-container {
  mat-dialog-container {
    overflow: visible;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-size: $select-size;

  &.ng-option-marked {
    background-color: #3297fd;
    color: #ffffff;
  }
}

@media only screen and (max-width: 600px) {
  .ng-dropdown-panel-items {
    padding: 8px 10px;

    span {
      font-size: $select-size;
    }
  }

  ng-select.has-input-icon {
    .ng-dropdown-panel-items {
      padding: 8px 20px;
    }
  }
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e9ecef;
  opacity: 1;
}

.warning-color {
  color: $red;
}

.service-plan-popup-overlay {
  height: auto;
  max-height: 100%;
  overflow: auto;
}

// ========================================================================================================
// ng-multiselect-dropdown styles
// ========================================================================================================
$base-color: $primary-color;
$disable-background-color: #eceeef;

.multiselect-dropdown {
  position: relative;
  width: 100%;
  font-family: inherit;

  height: 32px;
  border-radius: 0.25rem;
  background-color: $background;
  font-size: 12px;

  .dropdown-btn {
    display: inline-block;
    z-index: 9999999;
    font-size: 12px;
    height: 32px;
    width: 100%;
    border: solid 2px #cdcdcd !important;
    border-radius: 0.25rem;
    background-color: $background;

    width: 100%;
    margin-bottom: 0;
    font-weight: normal;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;

    .selected-item {
      margin-right: 4px;
      background: $base-color !important;
      padding: 0px 5px;
      color: #fff;
      border-radius: 2px;
      float: left;

      a {
        text-decoration: none;
      }
    }

    .selected-item:hover {
      box-shadow: 1px 1px #959595;
    }

    .dropdown-down {
      display: contents;

      top: 10px;
      width: 0;
      height: 0;
      border-top: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    .dropdown-up {
      display: contents;

      width: 0;
      height: 0;
      border-bottom: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }

  .disabled {
    & > span {
      background-color: $disable-background-color;
    }
  }
}

.dropdown-list {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  z-index: 9999;
  border-radius: 3px;
  background: #fff;
  margin-top: 10px;

  display: contents;
  height: 32px;
  border: solid 2px #cdcdcd;
  border-radius: 0.25rem;
  padding: 0 8px;
  font-size: 12px;

  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
    background-color: $background;
  }

  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
    background-color: $background;
  }

  .filter-textbox {
    position: relative;
    padding: 10px;

    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }

    input:focus {
      outline: none;
    }
  }
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: $base-color !important;
  background-color: #f2f2f2;
  content: none;
}

.multiselect-item-checkbox input[type='checkbox']:active + div:before {
  transition-duration: 0s;
  content: none;
}

.selected-item {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
  margin-bottom: 10px;
}

.multiselect-item-checkbox input[type='checkbox'] + div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
  box-sizing: content-box;
  content: '';
  color: $base-color !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid $base-color !important;
  text-align: center;
  transition: all 0.4s ease;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  box-sizing: content-box;
  content: '';
  background-color: $base-color !important;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
  border-color: #cccccc;
}

.multiselect-item-checkbox
  input[type='checkbox']:disabled:focus
  + div:before
  .multiselect-item-checkbox
  input[type='checkbox']:disabled:hover
  + div:before {
  background-color: inherit;
}

.multiselect-item-checkbox
  input[type='checkbox']:disabled:checked
  + div:before {
  background-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.dropdown-multiselect__caret::before {
  display: none;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  animation: borderscale 200ms ease-in;
  background: $base-color !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  transform: rotate(-45deg) scale(1);
}

.multiline-tooltip {
  white-space: pre-line;
  text-align: center;
}

.confined-space-entry-dialog .mat-mdc-dialog-container {
  transform: translateY(-15%) !important;
}

app-external-ncr-creation {
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: center;
}

.flex-form-toast-button {
  padding: 0 10px;
  text-decoration: none;
}
.main-nav-logout-container.mat-mdc-menu-panel {
  border-radius: 0.5rem !important;

  .mat-mdc-menu-content {
    display: flex;
    border-radius: 0.5rem;
    padding: 0.8rem;
    background-color: $primary-color;

    button {
      padding: 0px;
      min-height: fit-content;
      color: $menu-lighter-text;

      p {
        font-size: $base-text-size;
      }

      .logout-option {
        display: flex;
        padding: 0 0.8rem;
        gap: $base-gap-size;
      }
    }

    span {
      display: flex;
      gap: $base-gap-size;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.cdk-overlay-pane {
  border-radius: 16px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: $large-gap-size;

  @supports not (selector(::-webkit-scrollbar)) {
    scrollbar-color: $primary-color transparent;
  }

  @supports selector(::-webkit-scrollbar) {
    &:hover {
      --safari-fix: ; /* This custom property invalidates styles on hover, thereby enforcing a style recomputation. This is needed to work around a bug in Safari. */
    }
    &::-webkit-scrollbar-thumb {
      height: 50px;
      border-radius: 8px;
      background: $primary-color;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar {
      max-width: 6px;
      max-height: var(--scrollbar-width-legacy);
    }
  }
}

@include breakpoint(mobile) {
  #cdk-overlay-0 {
    max-height: 90%;
  }
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $base-color !important;
  }
}
// ========================================================================================================
